import React, { Suspense, lazy } from "react";
import { Routes, Route } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
// import 'bootstrap/dist/css/bootstrap.min.css';
// import "./assets/css/toolbar.css";

// Lazy loading components
const Home = lazy(() => import("./components/Home"));
const About = lazy(() => import("./components/About"));
const Contact = lazy(() => import("./components/Contact"));
const Gallery = lazy(() => import("./components/Gallery"));
const Blog = lazy(() => import("./components/Blog"));
const Pressnote = lazy(() => import("./components/Pressnote"));
const Awards = lazy(() => import("./components/Awards"));
const Projects = lazy(() => import("./components/Projects"));
const Blogview = lazy(() => import("./components/Blogview"));
const Projectdetails = lazy(() => import("./components/Projectdetails"));
const Policy = lazy(() => import("./components/Policy"));
const Terms = lazy(() => import("./components/Terms"));
const Disclaim = lazy(() => import("./components/Disclaim"));
const Test = lazy(() => import("./components/Test"));

function App() {
  return (
    <div>
      <Suspense
        fallback={
          <div className="preloader">
            <div className="preloader__image" />
          </div>
        }
      >
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/awards" element={<Awards />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/blogview" element={<Blogview />} />
          <Route path="/pressnote" element={<Pressnote />} />
          <Route path="/project" element={<Projects />} />
          <Route path="/projects/:projectName" element={<Projectdetails />} />
          <Route path="/privacypolicy" element={<Policy />} />
          <Route path="/termsofservice" element={<Terms />} />
          <Route path="/disclaimer" element={<Disclaim />} />
          <Route path="/test" element={<Test />} />
        </Routes>
      </Suspense>
    </div>
  );
}

export default App;
